<template>
	<div class="flex-grow-1" style="position: relative;">
		<div style="overflow: auto; position: absolute; left: 0; top: 0; bottom: 180px; right: 0;" id="previousNotes">
			<div v-for="note in notes" :key="note.id" class="note">
				<div class="bubble">
					<div class="media">
						<div class="media-body align-self-center" @click="selectNote(note)">
							{{ note.text }}
						</div>
						<button @click="deleteNote(note.id)" v-if="note.id">
							<font-awesome-icon icon="times"/>
						</button>
					</div>
					<div class="blockquote-footer mt-2" v-if="note.modifiedAt">{{ formatTime(note.modifiedAt) }}</div>
					<div class="blockquote-footer mt-2" v-else>{{ formatTime(note.createdAt) }}</div>
				</div>
			</div>
		</div>

		<div style="height: 180px; position: absolute; bottom: 0; left: 0; right: 0;" class="d-flex flex-column">
			<hr />
			<textarea id="noteText" class="form-control flex-grow-1" v-model="editedNote.text" :placeholder="$t('notes.createNew')"></textarea>
			<div class="mt-2 media">
				<span class="text-secondary media-body">{{ $t('notes.note') }}</span>
				<button class="btn btn-primary float-right ml-2 h-100" @click="saveNote()">
					<font-awesome-icon icon="check"/>
					{{ $t('notes.save') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'NoteListView',
	data() {
		return {
			notes: [],
			editedNote: { text: '' }
		};
	},
	mounted() {
		let data = window.consentedStorage.getItem('notes');
		if(data){
			if(data.startsWith('[') && data.endsWith(']')) {
				this.notes = JSON.parse(data);
			}
		}

		this.scrollToEnd(false);
	},
	methods: {
		scrollToEnd(animated) {
			setTimeout(() => {
				let d = $('#previousNotes');
				if(animated) {
					d.animate({ scrollTop: d.prop("scrollHeight")}, 1000);
				}
				else {
					d.scrollTop(d[0].scrollHeight);
				}
			}, 1);
		},
		saveNote() {
			let isNew = !this.editedNote.createdAt;
			if(isNew) {
				this.editedNote.createdAt = new Date().getTime();
			}

			window.newEvent('NOTE');

			this.editedNote.modifiedAt = new Date().getTime();
			if(!this.editedNote.id) {
				this.editedNote.id = uuidv4();
				this.notes.push(this.editedNote);
			}
			else {
				for(let i = 0; i < this.notes.length; i++) {
					if(this.notes[i].id == this.editedNote.id) {
						this.notes[i] = JSON.parse(JSON.stringify(this.editedNote));
						break;
					}
				}
			}
			window.consentedStorage.setItem('notes', JSON.stringify(this.notes));

			this.editedNote = { text: '' };

			if(isNew) {
				this.scrollToEnd(true);
			}
		},
		async deleteNote(id) {
			if(await this.$confirm(this.$t('notes.deleteConfirm'))) {
				let reset = this.editedNote && id == this.editedNote.id;

				for(let i = 0; i < this.notes.length; i++) {
					if(this.notes[i].id == id) {
						this.notes.splice(i, 1);
						break;
					}
				}
				window.consentedStorage.setItem('notes', JSON.stringify(this.notes));

				if(reset) {
					this.editedNote = { text: '' };
				}
			}
		},
		selectNote(note) {
			this.editedNote = JSON.parse(JSON.stringify(note));
		},
		formatTime(epoch) {
			function padLeft(v, len) {
				v = '' + v;
				while(v.length < len) {
					v = '0' + v;
				}
				return v;
			}

			let d = new Date(parseInt(epoch));
			return d.getFullYear() + '-' + padLeft(d.getMonth(), 2) + '-' + padLeft(d.getDay(), 2) + ' ' +
				d.getHours() + ":" + padLeft(d.getMinutes(), 2) + ":" + padLeft(d.getSeconds(), 2);
		}
	}
}
</script>

<style scoped>
	.note {
		text-align: left;
	}

	hr {
		height: 10px;
		margin: 0;
	}

	.note > .bubble {
		background-color: #f8f1ae;
		padding: 5px 0 5px 10px;
		margin: 8px 5px;
		display: inline-block;
		white-space: pre-line;
		display: block;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
		border-radius: 2px;
		border: 1px solid #a39e6d;
		text-align: justify;
	}

	.note > .bubble button {
		color: #ff0000;
		background: none;
		border: none;
		padding: 5px 10px;
		margin-left: 5px;
		transform: translateY(-6px);
	}
</style>
